import { v1 as uuid } from "uuid";
export default {
  edit: {
    visible: false,
    type: "",
    title: "",
  },
  color: false,
  office: {
    id: "5e8e36b31c9d440000d35090",
    name: "GPRB Gestión Inmobiliaria Industrial",
    email: "giancarlo@gprb.cl",
    address: "GPRB Gestión Inmobiliaria Industrial",
    phone: "+56 9 42283886",
    mobile: "+56 9 42283886",
    typeId: "office",
    lat: "-33.410167",
    lng: "-70.655265",
  },
  main: {
    primaryColor: "#252b86",
    secondaryColor: "#ffffff",
    favicon: "",
    logo: {
      isImage: true,
      value: "/logo-dark.png",
    },
    logoDark: {
      isImage: true,
      value: "/logo.png",
    },
  },
  home: {
    hero: {
      visible: true,
      title: "Soporte de calidad en la toma de decisiones.",
      background: "/hero5.jpg",
    },
    properties: {
      visible: true,
      title: "Contamos con una selección exclusiva de propiedades.",
      footer:
        "Estas son solo algunas de las propiedades que tenemos para ofrecerte",
      bannerImage: "",
      buttonText: "Ver más",
      items: [
        {
          id: uuid(),
          mainImage: "/property-1.png",
          title: "Casa en lo Barnechea",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Valle del Monasterio / manquele oriente",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 4,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-2.png",
          title: "Casa en Vitcura",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Lo Curro",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 8,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-3.png",
          title: "Casa en Las Condes",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Malaga",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 6,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-4.png",
          title: "Casa en lo Barnechea",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Rosario / Casorra Alzamona",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 6,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-1.png",
          title: "Casa en lo Barnechea",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Valle del Monasterio / manquele oriente",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 4,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-2.png",
          title: "Casa en Vitcura",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Lo Curro",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 8,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-3.png",
          title: "Casa en Las Condes",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Malaga",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 6,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-4.png",
          title: "Casa en lo Barnechea",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Rosario / Casorra Alzamona",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 6,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-1.png",
          title: "Casa en lo Barnechea",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Valle del Monasterio / manquele oriente",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 4,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-2.png",
          title: "Casa en Vitcura",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Lo Curro",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 8,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-3.png",
          title: "Casa en Las Condes",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Malaga",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 6,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
        {
          id: uuid(),
          mainImage: "/property-4.png",
          title: "Casa en lo Barnechea",
          currency: "UF",
          value: 75000,
          operation: "Venta",
          code: "345678",
          ubication: {
            address: "Rosario / Casorra Alzamona",
          },
          characteristics: [
            {
              id: "5f0591b437096b0008b019d5",
              name: "Habitaciones",
              value: 6,
            },
            {
              id: "5f0591b437096b0008b019d5",
              name: "Sup. Total",
              value: 1000,
            },
          ],
        },
      ],
    },
    about: {
      banner: {
        visible: true,
        image: "/about-description.jpg",
        title:
          "Somos una empresa de gestión inmobiliaria integral con más de 15 años de experiencia.",
        subTitle:
          "A través de los años nos hemos destacado por nuestra atención personalizada con nuestros clientes acompañándolos en todas las etapas de negociación y comercialización de los activos.",
        buttonText: "Conózcanos",
      },
    },
    services: {
      visible: true,
      items: [
        {
          id: uuid(),
          title: "Compras",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium fringilla ex at hendrerit",
        },
        {
          id: uuid(),
          title: "Ventas",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium fringilla ex at hendrerit",
        },
        {
          id: uuid(),
          title: "Arriendo",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium fringilla ex at hendrerit",
        },
        {
          id: uuid(),
          title: "Compras",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium fringilla ex at hendrerit",
        },
        {
          id: uuid(),
          title: "Ventas",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium fringilla ex at hendrerit",
        },
        {
          id: uuid(),
          title: "Arriendo",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium fringilla ex at hendrerit",
        },
      ],
    },
    reviews: {
      visible: true,
      items: [
        {
          review:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate sapien at nisi volutpat dictum. Maecenas vel lorem tellus. Sed sit amet sollicitudin nisi. Sed consectetur eget orci sit amet condimentum.",
          author: "Cliente 1",
        },
        {
          review:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate sapien at nisi volutpat dictum. Maecenas vel lorem tellus. Sed sit amet sollicitudin nisi. Sed consectetur eget orci sit amet condimentum.",
          author: "Cliente 2",
        },
        {
          review:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate sapien at nisi volutpat dictum. Maecenas vel lorem tellus. Sed sit amet sollicitudin nisi. Sed consectetur eget orci sit amet condimentum.",
          author: "Cliente 3",
        },
      ],
    },
    contact: {
      visible: true,
      title:
        "¿Necesitas vender, arrendar o comprar una propiedad? Somos tu socio perfecto.",
      buttonText: "contacto",
    },
  },
  about: {
    hero: {
      visible: true,
      title:
        "Un equipo de profesionales especializados en el sector industrial.",
      background: "/hero3.jpg",
    },
    history: {
      visible: true,
      title: "Nuestra Historia",
      description:
        "<p>Somos una empresa de gestión inmobiliaria integral con más de 15 años de experiencia, fuertemente enfocada en el mercado del corretaje de propiedades industriales y comerciales. Dentro de nuestra área industrial arrendamos y vendemos bodegas y terrenos industriales a lo largo de todo Chile, en nuestra área comercial arrendamos y vendemos locales comerciales y oficinas. A través de los años nos hemos destacado por una atención personalizada con nuestros clientes, acompañándolos en todas las etapas de negociación y comercialización de los activos.  <p/>",
    },
    description: {
      visible: true,
      background: "/about-description.jpg",
      items: [
        {
          title: "Misión",
          description:
            "Somos una empresa gestora inmobiliaria orientada a satisfacer las necesidades inmobiliarias industriales para nuestros clientes, más de 10 años de experiencia en el mercado inmobiliario industrial y comercial nos avalan, arrendamos y vendemos bodegas y terrenos industriales a lo largo de todo Chile. Nos destacamos por nuestra atención diferenciadora, eficiente y personalizada con nuestros clientes. ",
        },
        {
          title: "Visión",
          description:
            "Queremos ser reconocidos como una empresa eficiente y cercana a nuestros clientes, día a día mejoraremos nuestros procesos internos para entregar una solución integral a los requerimientos de nuestros clientes para lograr ser reconocidos en el mercado inmobiliario industrial y comercial.",
        },
        {
          title: "Valores",
          description:
            "En el ámbito de todo negocio inmobiliario, que nuestros clientes nos reconozcan como la mejor opción para exigir el máximo provecho a sus recursos inmobiliario.",
        },
        {
          title: "Misión",
          description:
            "En el ámbito de todo negocio inmobiliario, somos el soporte de calidad en la toma de decisiones.",
        },
        {
          title: "Visión",
          description:
            "En el ámbito de todo negocio inmobiliario, que nuestros clientes nos reconozcan como la mejor opción para exigir el máximo provecho a sus recursos inmobiliario.",
        },
        {
          title: "Valores",
          description:
            "En el ámbito de todo negocio inmobiliario, que nuestros clientes nos reconozcan como la mejor opción para exigir el máximo provecho a sus recursos inmobiliario.",
        },
      ],
    },
    stats: {
      visible: true,
      items: {
        years: {
          value: 50,
          meta: "Años en el mercado",
        },
        transactions: {
          value: 500,
          meta: "Ventas y arriendos anuales",
        },
        properties: {
          value: 1000,
          meta: "Propiedades en administración",
        },
        proffesionals: {
          value: 70,
          meta: "Profesionales",
        },
      },
    },
    team: {
      visible: true,
      items: [
        {
          id: uuid(),
          firstName: "Usuario",
          lastName: "1",
          fullName: "Usuario 1",
          cv:
            "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
          avatar: "/team-member-1.jpg",
          email: "usuario1@example.com",
          phone: "+56 9 5555 5555",
        },
        {
          id: uuid(),
          firstName: "Usuario",
          lastName: "2",
          fullName: "Usuario 2",
          cv:
            "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
          avatar: "/team-member-2.jpg",
          email: "usuario2@example.com",
          phone: "+56 9 5555 5555",
        },
        {
          id: uuid(),
          firstName: "Usuario",
          lastName: "3",
          fullName: "Usuario 3",
          cv:
            "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
          avatar: "/team-member-3.jpg",
          email: "usuario3@example.com",
          phone: "+56 9 5555 5555",
        },
        {
          id: uuid(),
          firstName: "Usuario",
          lastName: "4",
          fullName: "Usuario 4",
          cv:
            "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades.  ",
          avatar: "/team-member-4.jpg",
          email: "usuario4@example.com",
          phone: "+56 9 5555 5555",
        },
      ],
    },
    ubication: {
      visible: true,
      title:
        "Encuéntranos en Sucursal Chicureo Camino a Chicureo 2 km. esquina Los Ingleses Colina - Santiago",
      location: {
        coordinates: ["0", "0"],
      },
    },
  },
  news: {
    hero: {
      visible: true,
      title: "Noticias",
      background: "/news-background.jpg",
    },
    items: [
      {
        id: uuid(),
        title: "¿Qué documentos necesito para vender mi propiedades?",
        tag: "Legales",
        image: "/new-1.jpg",
        date: "Junio 17, 2020",
      },
      {
        id: uuid(),
        title: "¿Que hay que tener en cuenta a la hora de realizar una visita?",
        tag: "Noticias",
        image: "/new-2.jpg",
        date: "Junio 17, 2020",
      },
      {
        id: uuid(),
        title: "Las mejores locaciones para arriendo del 2019",
        tag: "Noticias",
        image: "/new-3.jpg",
        date: "Junio 17, 2020",
      },
      {
        id: uuid(),
        title: "¿Qué documentos necesito para vender mi propiedades?",
        tag: "Legales",
        image: "/new-1.jpg",
        date: "Junio 17, 2020",
      },
      {
        id: uuid(),
        title: "¿Que hay que tener en cuenta a la hora de realizar una visita?",
        tag: "Noticias",
        image: "/new-2.jpg",
        date: "Junio 17, 2020",
      },
      {
        id: uuid(),
        title: "Las mejores locaciones para arriendo del 2019",
        tag: "Noticias",
        image: "/new-3.jpg",
        date: "Junio 17, 2020",
      },
    ],
  },
  singleProperty: {
    mainImage: "/property/main.jpg",
    images: [
      "/property/main.jpg",
      "/property/1.jpg",
      "/property/2.jpg",
      "/property/3.jpg",
      "/property/4.jpg",
      "/property/5.jpg",
    ],
    publicObservation:
      "Esta es la descripción púbica hecha pr el usuario donde pone lo que quiera poner. Hermosa Casa en lomas verdes, con 3 pisos. Aqui vivio drew Barirmore durante su corta estadia en Chile.",
    operation: "Arriendo",
    code: "EB092734",
    title: "Casa en Santiago de Chile",
    currency: "UF",
    value: "75.000",
    ubication: {
      region: "Rosario",
      commune: "Casorra alzamona",
      location: {
        coordinates: ["0", "0"],
      },
    },
    characteristics: [
      {
        type: "GENERAL",
        id: uuid(),
        name: "Casa",
        icon: "House",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Precio",
        icon: "Price",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Construida el 12/06/98",
        icon: "Building",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Sup. Construida 100m2",
        icon: "Surface",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Sup. Total 180m2",
        icon: "Surface",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Baños 2",
        icon: "Bath",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Venta",
        icon: "Sale",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Balcon",
        icon: "Balcony",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Habitaciones 3",
        icon: "Rooms",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Pisos 2",
        icon: "Floor",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Estacionamientos",
        icon: "Parking",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Gasto común 0",
        icon: "Spending",
      },
      {
        type: "GENERAL",
        id: uuid(),
        name: "Calefación",
        icon: "Heating",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Baño de Servicio",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Calefacción central",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Balcón",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Cerca a areas verdes",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Sist. de alarma",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Seguridad 24 hs",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Cuarto de servicio",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Pátio",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Piscina",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Chimenea",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Área verde",
        icon: "Extra",
      },
      {
        type: "OTHERS",
        id: uuid(),
        name: "Terraza",
        icon: "Extra",
      },
    ],
    relatedUser: {
      id: uuid(),
      firstName: "Adrian",
      lastName: "Carcamo",
      description:
        "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
      avatar: "/team-member-1.jpg",
      email: "acarcamo@clasihome.cl",
      phone: "+56 9 5555 5555",
      jobTitle: "Ejecutivo comercial",
    },
  },
  singleNew: {
    hero: {
      visible: true,
      background: "/new-hero.jpg",
      title: "¿Qué documentos necesito para vender mi propiedades?",
      tag: "legales",
      date: "Agosto 24, 2020",
    },
    intro: {
      title:
        "<p>orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. In culpa qui officia deserunt mollit anim id est laborum.<p><p>Excepteur sint occaecat cupidatat, dolore eu fugiat nulla pariatur non proident. Dolorem sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et fugiat nulla pariatur dolore magna aliqua. Irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<p>",
      footer:
        "“¿Necesitas vender, arrendar o comprar una propiedad? Somos tu socio perfecto”",
    },
    article: {
      image: "/article.jpg",
      text:
        "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt <a href='/'>ut labore et dolore</a> magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. In culpa qui officia deserunt mollit anim id est laborum.<p><p>Excepteur sint occaecat cupidatat, dolore eu fugiat nulla pariatur non proident. Dolorem sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et fugiat nulla pariatur dolore magna aliqua. Irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Nam tempor utamur gubergren no. Pri quas audiam virtute ut, case utamur fuisset eam ut, dolor in reprehenderit iisque accommodare an eam. Reque blandit qui eu, cu vix nonumy volumus. Intellegam fugiat nulla pariatur id usu, vide oporteat vix eu, id illud principes has.</p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<p/>",
    },
  },
};
